<template>
	<div class="shopping-section col" align-x="left">
		<el-form :model="$data" ref="ruleForm" inline label-position="top">
			<el-form-item prop="selectedId" label="顏色、尺寸" :rules="{required:true, message:'請選擇顏色、尺寸'}">
				<el-select v-model="selectedId" class="round">
					<template v-for="(item, i) in options">
						<el-option :value="item.id" :label="item.label" :disabled="optionAvalible(item)" :key="i" />
					</template>
				</el-select>
			</el-form-item>
			<el-form-item prop="amount" label="數量" :rules="{required:true, message:'請選擇數量'}">
				<el-input-number v-model="amount" :min="1" :max="max" :disabled="!this.selected" />
			</el-form-item>
		</el-form>
		<el-button @click="handleAddToCart()" type="theme" round>加入購物車</el-button>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	data() {
		return {
			selectedId: "",
			amount: 1
		}
	},
	computed: {
		...mapState("product", ["_product"]),
		...mapState("order", ["_cart"]),
		...mapState("auth", ["_auth"]),
		options() {
			const { options = new Array } = this._product;
			return options
				.map(item => ({
					...item,
					label: (item.color || "無色") + "、" + (item.size || "無尺寸"),
				}));
		},
		selected: {
			get() {
				const { options = new Array } = this._product;
				return this._.find(options, ["id", this.selectedId]);
			},
			set(id) {
				this.selectedId = id;
			}
		},
		max() {
			const { id, amount = 0 } = this.selected || new Object;
			const item = this._.find(this._cart, {
				productId: this._product.id,
				optionId: this.selectedId
			});
			return amount - (item && item.amount || 0);
		},
	},
	filters: {
		optionAvalible({ id, amount }) {
			const item = this._.find(this._cart, {
				productId: this._product.id,
				optionId: id
			});
			return amount - (item && item.amount || 0) <= 0;
		}
	},
	methods: {
		...mapActions("order", ["_addToCart"]),
		// 判斷選項是否還有庫存（跟購物車的數據合併）
		optionAvalible({ id, amount }) {
			const item = this._.find(this._cart, {
				productId: this._product.id,
				optionId: id
			});
			return amount - (item && item.amount || 0) <= 0;
		},
		resetForm() {
			this.selected = "";
			this.amount = 0;
			this.$refs["ruleForm"].resetFields();
		},
		handleAddToCart() {
			if (!this._auth) this.$confirm("請先登入", "提示", {
				type: "warning",
				confirmButtonText: "前往登入",
			}).then(() => {
				this.$router.push("/login");
			});
			else this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					await this._addToCart({
						productId: this._product.id,
						optionId: this.selectedId,
						amount: this.amount,
						...this._.pick(this.selected, ["color", "size"]),
						...this._.pick(this._product, ["name", "thumb", "price", "special_price"])
					});
					this.resetForm();
					this.$message.success("已加入購物車");
				}
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.el-button {
	font-size: 1em;
	padding: 0.5em 1.5em;
}
</style>